import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/vercel/path0/apps/template-admin/middleware/01.auth.global.ts";
import _02_45csrf_45global from "/vercel/path0/apps/template-admin/middleware/02.csrf.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.16.10_eslint@9.10.0_jiti@2.4.2__ioredis@5.4._5t7nc4up4mi4pzutt6izoqjzzm/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45csrf_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}