import type { HTTPMethod } from 'h3'
import type { FetchContext, FetchOptions } from 'ofetch'

import { defu } from 'defu'

export const useFetchWithDefaults = () => {
  const config = useRuntimeConfig()
  const csrfToken = useCsrfToken()

  const defaultFetchOptions = computed<FetchOptions>(() => ({
    baseURL: config.public.apiBaseUrl,
    credentials: 'include',
    headers: {
      accept: 'application/json',
      'X-XSRF-TOKEN': csrfToken.value || '',
    },
    onResponseError: (context) => {
      if (context.response?.status === 401) {
        navigateTo(`${config.public.loginUrl}?return=${encodeURIComponent(location.href)}`, { external: true })
      }
    },
    onRequest: ({ options }) => {
      if (options.params) {
        const paramsToJoin = Object.entries(options.params)
          .filter(([key, value]) =>
            Array.isArray(value) && (
              key === 'include'
              || key === 'sort'
              || key.startsWith('filter[')),
          )

        paramsToJoin.forEach(([key, value]) => {
          if (options.params) // to satisfy typescript
            options.params[key] = value.join(',')
        })
      }
    },
  }))

  const fetch = (httpMethod: HTTPMethod) =>
    <ResponseT>({
      url,
      fetchOptions = {},
    }: {
      url: string
      fetchOptions?: Omit<FetchOptions, 'method'>
    }) =>
      $fetch<ResponseT>(
        url,
        defu(
          {
            onRequest: (context: FetchContext) => {
              const callHooksOrHook = (hooksOrHook: FetchOptions['onRequest'] | undefined) => {
                if (Array.isArray(hooksOrHook))
                  hooksOrHook.forEach(hook => hook(context))
                else
                  hooksOrHook?.(context)
              }

              callHooksOrHook(fetchOptions.onRequest)
              callHooksOrHook(defaultFetchOptions.value.onRequest)
            },
          },
          fetchOptions,
          {
            method: httpMethod,
          },
          defaultFetchOptions.value,
        ),
      )

  return {
    get: fetch('GET'),
    put: fetch('PUT'),
    post: fetch('POST'),
    patch: fetch('PATCH'),
    deleteRequest: fetch('DELETE'),
  }
}
